import { Theme } from 'src/ui-kit/theme/types';
import { phone, tablet_desktop } from '../../styles/media';
import getStyles from './HomeSlider.styles';

interface Props {
  image: any;
  currentLocale: string;
  theme: Theme;
  onLoad?(): void;
}

const DefaultSlide = (props: Props) => {
  const { image, theme, onLoad } = props;
  const styles = getStyles(theme);

  return (
    <div>
      <picture>
        <source media={tablet_desktop} srcSet={image.desktop.url} />
        <source
          media={phone}
          srcSet={image.mobile.url}
        />
        <img
          className='HomeSlider__slideImage'
          src={image.desktop.url}
          alt='Banner'
          onLoad={onLoad}
        />
      </picture>
      <style jsx>{styles}</style>
    </div>
  );
};

export default DefaultSlide;
