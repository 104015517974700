
import dynamic from 'next/dynamic';
import GeneralPagesLayout from 'src/layouts/GeneralPagesLayout';
import { SearchAndCategoriesSlider } from 'src/modules/mainPage';
import { requestGetRetailChains } from 'src/modules/retails';
import useCanonicalHref from 'src/utils/useCanonicalHref';
import { RetailChain } from '../../api/types';
import AppSection from '../../components/AppSection/AppSection';
import HomeHero from '../../components/HomeHero/HomeHero';
import HomeRetails from '../../components/HomeRetails/HomeRetails';
import HomeSlider from '../../components/HomeSlider/HomeSlider';
import HowSection from '../../components/HowSection';
import MainSiteMeta from '../../components/MainSiteMeta/MainSiteMeta';
import PromotionSection from '../../components/PromotionSection';
import { LANGUAGES, NEW_YEAR_AVAILABLE, WITH_UBERCATALOG } from '../../data/constants';
import { useLocalize } from '../../services/localize/useLocalize';

const NewYearAnimation = dynamic(
  () => import('../../components/NewYear/NewYearAnimation'),
  { ssr: false },
);

interface Props {
  chains: RetailChain[];
}

function HomePage(props: Props) {
  const { chains } = props;
  const localize = useLocalize();
  const canonical = useCanonicalHref();

  return (
    <GeneralPagesLayout>
      <MainSiteMeta
        title={localize('meta_main_title')}
        description={localize('meta_main_description')}
        canonical={canonical}
      />

      <HomeHero />
      <HomeRetails />
      <HomeSlider />
      <AppSection />

      {WITH_UBERCATALOG && (
        <SearchAndCategoriesSlider />
      )}

      {chains && (
        <PromotionSection
          chains={chains}
        />
      )}

      {NEW_YEAR_AVAILABLE && (
        <NewYearAnimation />
      )}

      <HowSection />
    </GeneralPagesLayout>
  );
}

export async function getStaticPaths() {
  return {
    paths: LANGUAGES.map(l => `/${l}`),
    fallback: false,
  };
}

export async function getStaticProps() {
  const chains = await requestGetRetailChains();

  const props: Props = {
    chains,
  };

  return {
    props,
    revalidate: 10,
  };
}

export default HomePage;
