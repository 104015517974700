import { Theme } from 'src/ui-kit/theme/types';
import css from 'styled-jsx/css';
import { phone, tablet } from '../../styles/media';

const getStyles = (theme: Theme) => {
  return css`
  .HomeRetails {
    padding: 0 0 40px;
    background-color: ${theme.base.color.gray100};
  }

  .HomeRetails__container {
    max-width: 1130px;
    margin: 0 auto;
  }

    /* tablet */
  @media ${tablet} {
    .HomeRetails__container {
      max-width: 960px;
      padding: 0 32px;
    }
  }

    /* mobile */
  @media ${phone} {
    .HomeRetails__container {
      max-width: 432px;
      padding: 0 16px;
    }
  }
  `;
};

export default getStyles;
