import Image from 'next/image';
import { useTheme } from 'src/ui-kit/theme';
import { useLocalize } from '../../services/localize/useLocalize';
import { Typography } from '../../ui-kit/Typography';
import Benefit from '../../UIKitOld/Benefit/Benefit';
import getStyles from './HomeHero.styles';

const HomeHero = () => {
  const localize = useLocalize();
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <div className='HomeHero'>
      <div className='HomeHero__container'>
        <div className='HomeHero__textBlock'>
          <Typography variant={{general: 'h1', phone: 'h3' }}>
            {localize('service_delivery_products')}
          </Typography>

          <ul className='HomeHero__benefitList'>
            <li className='HomeHero__benefitItem'>
              <Benefit
                iconName='basket'
                text={localize('delivery_description')}
              />
            </li>

            <li className='HomeHero__benefitItem'>
              <Benefit
                iconName='price'
                text={localize('prices_at_store')}
              />
            </li>

            <li className='HomeHero__benefitItem'>
              <Benefit
                iconName='fresh'
                text={localize('select_fresh_products')}
              />
            </li>

            <li className='HomeHero__benefitItem'>
              <Benefit
                iconName='clock'
                text={localize('delivery_at_convenient_time')}
              />
            </li>
          </ul>
        </div>

        <div className='HomeHero__imgBlock'>
          <Image
            className='HomeHeroContainer__customerImage'
            src={'/static/images/home-hero.png'}
            alt={'happy customer'}
            width={239}
            height={342}
          />
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default HomeHero;
