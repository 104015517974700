import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import CenteringComponent from "src/components/CenteringComponent";
import Responsive from "src/components/Responsive";
import { HEADER_HEIGHT, MARGIN_UNDER_HEADER } from "src/modules/header/components/Header/Header.styles";
import SearchBox from "src/modules/search/components/SearchBox/SearchBox";
import useSearchBox from "src/modules/search/components/SearchBox/useSearchBox";
import useCategories from "src/services/categories/useCategories";
import useLang from "src/services/language/useLang";
import useLocalize from "src/services/localize/useLocalize";
import { phone } from "src/styles/media";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme/UseTheme";
import { Typography } from "src/ui-kit/Typography";
import CategoriesTileSlider, { CategoriesTileSliderProps } from "../CategoriesTileSlider/CategoriesTileSlider";

const SearchAndCategoriesSlider = () => {
  const localize = useLocalize();
  const theme = useTheme();
  const lang = useLang();
  const categories = useCategories();
  const searchBoxProps = useSearchBox('landing');
  const elementRef = useRef<HTMLDivElement>(null);
  const isPhone = useMediaQuery({ query: phone });

  const [searchBoxIsVisible, setSearchBoxIsVisible] = useState(false);

  const scrollToElement = () => {
    return new Promise((resolve) => {
      if (elementRef && elementRef.current) {
        const offsetTop = HEADER_HEIGHT + MARGIN_UNDER_HEADER;
        const elementPosition = elementRef.current.getBoundingClientRect().top - 1 + window.scrollY;

        // Прокручиваем к элементу
        window.scrollTo({
          top: elementPosition - offsetTop,
          behavior: 'smooth',
        });

        // Отслеживаем завершение прокрутки с помощью requestAnimationFrame
        const checkIfScrollFinished = () => {
          const currentScrollPosition = window.scrollY;

          // Проверяем, достигнута ли целевая позиция
          if (Math.abs(currentScrollPosition - (elementPosition - offsetTop)) < 1) {
            resolve(true);  // Прокрутка завершена, возвращаем true
          } else {
            requestAnimationFrame(checkIfScrollFinished);
          }
        };

        // Запускаем цикл отслеживания завершения прокрутки
        requestAnimationFrame(checkIfScrollFinished);
      } else {
        resolve(false);  // На случай, если elementRef не существует
      }
    });
  };

  const categoriesTileSliderProps: CategoriesTileSliderProps = {
    categories,
    lang,
    theme,
  };

  return (
    <CenteringComponent dataMarker="SearchAndCategoriesSlider">
      <StylishBox
        sbs={{
          py: {
            general: '96px',
            phone: '40px',
          },
          px: {
            phone: '12px',
            tablet: '32px',
          },
          mx: 'auto',
          maxWidth: {
            tablet: '960px',
          },
        }}
      >
        <Typography
          variant="caption2"
          sbs={{
            mb: '12px',
            lineHeight: '12px',
          }}
          color="primary"
          elementProps={{
            ref: elementRef,
          }}
          dataMarker="Search line title"
        >
          {localize('combined_catalogue_of_all_stores')}
        </Typography>

        <SearchBox
          {...searchBoxProps}
          variant="mainPage"
          inputProps={{
            ...searchBoxProps.inputProps,
            onFocus: async (e) => {
              if (isPhone) {
                setSearchBoxIsVisible(true);
              } else {
                const scrollFinished = await scrollToElement();

                // Выполняем onFocus после завершения прокрутки
                if (scrollFinished && searchBoxProps.inputProps.onFocus) {
                  searchBoxProps.inputProps.onFocus(e);
                }
              }

            },
          }}
        />

        {searchBoxIsVisible && (
          <SearchBox
            {...searchBoxProps}
            variant="mobile"
            onClose={() => setSearchBoxIsVisible(false)}
          />
        )}

        {categories.length > 0 && (
          <StylishBox sbs={{ mt: '32px' }}>
            <Responsive displayOn="desktop">
              <CategoriesTileSlider
                {...categoriesTileSliderProps}
              />
            </Responsive>

            <Responsive displayOn="phoneTablet">
              <CategoriesTileSlider
                {...categoriesTileSliderProps}
                itemPerRow={3}
              />
            </Responsive>
          </StylishBox>
        )}
      </StylishBox>
    </CenteringComponent>
  );
};

export default SearchAndCategoriesSlider;
